var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"pXBwM5AicYdQ4EvU1Hr9r"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://439a81fcc6094d4780826875131b80e2@o937580.ingest.sentry.io/5887979",
  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (
      event.exception &&
      event.exception.values[0].value.startsWith("Got user feedback")
    ) {
      window.reportBug(event.event_id);
    }
    return event;
  },
  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: ["/apis_new/media_url", "/apis_new/status"],
    }),
  ],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.4,
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  enabled: process.env.NODE_ENV === "production",
});
